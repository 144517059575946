import logo from './crazee.png';
import './App.css';
import {useState, useEffect, useRef} from 'react';


function Logo() {
    return (
        <img src={logo} className="App-logo" alt="logo" />
    );
}

function ShowArchive() {
    const [player_show, setPlayerShow] = useState({'date': '', 'filename': '', 'source': ''});
    const [shows, setShows] = useState([]);
    const audioRef = useRef();
    useEffect(() => {
        if (player_show.source !== '' && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();
        }
    }, [player_show]);
    const getShows = () => {
        fetch('/shows/shows.json',{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function(response) {
            console.log(response);
            return response.json();
        })
        .then(function(json) {
            console.log(json);
            setShows(json);
        });
    }
    useEffect( () => {
        getShows()
    }, [])
    return (
        <div>
            <div className="mt20">
                <div>
                    <b>Show Archive:</b>
                </div>
                <div className="padding10 mt10">
                    <table>
                    {shows.map(function(show, i) {
                        if (show.playlist) {
                            if (show.source) {
                             return <tr className="fs08"><td>{show.date}</td><td><button className="ml10" type="button" onClick={() => setPlayerShow(show)}>Play</button></td><td><a className="ml20" href={show.playlist} target="_blank" rel="noreferrer">Playlist</a></td></tr>
                            } else {
                             return <tr className="fs08"><td>{show.date}</td><td>&nbsp;</td><td><a className="ml20" href={show.playlist} target="_blank" rel="noreferrer">Playlist</a></td></tr>
                            }
                        } else {
                            if (show.source) {
                                return <tr className="fs08"><td>{show.date}</td><td><button className="ml10" type="button" onClick={() => setPlayerShow(show)}>Play</button></td><td>&nbsp;</td></tr>
                            } else {
                                return <tr className="fs08"><td>{show.date}</td><td>&nbsp;</td><td>&nbsp;</td></tr>
                            }
                        }
                    })}
                    </table>
                </div>
            </div>
            <div className="mt20">
                <div>
                    <b>Now Playing:</b> <span id="now_playing" className="ml10 fs08">{player_show.date}</span>
                </div>
                <div className="padding10">
                    <audio controls autoPlay preLoad="auto" ref={audioRef}>
                        <source id="player" src={player_show.source} type="audio/mpeg" />
                    </audio>
                </div>
            </div>
        </div>
    );
}


function ContactInfo() {
    return (
        <div>
            <div className="mt10">
                <b>Contact Information:</b>
                <div className="ml10 fs08">
                    <p>Email: <a href="mailto:nut@thenuthouse.org">nut@thenuthouse.org</a></p>
                    <p>FB: <a href="https://www.facebook.com/thenuthouseradio" target="_blank" rel="noreferrer">@thenuthouseradio</a></p>
                    <p>WOMR: (508) 487-2619</p>
                </div>
            </div>
        </div>
    );
}

/*

                                <!--
                                    <li>Saturday, April 9, 2022 6->9am on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                    <li>Monday, April 18, 2022 9->11pm on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                    <li>Saturday, July 2, 2022 9pm->midnight on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                    <li>Wednesday, October 4, 2023 9pm->Midnight on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                -->
*/
function App() {
    const my_logo = Logo();
    const show_archive = ShowArchive();
    const contact_info = ContactInfo();
    return (
        <div className="App">
            <header className="App-header">
                <div className="logoText">
                    {my_logo}
                    ~ Welcome to The Nut House ~
                    {my_logo}
                </div>
            </header>
            <div className="content_area float_container">
                <div className="float_child">
                    <div id="left_content_area">
                        {show_archive}
                        {contact_info}
                    </div>
                </div>
                <div className="float_child">
                    <div id="right_content_area">
                        <div className="fs14 ml20">
                            <p><b>Upcoming Shows:</b>
                                <ul>
                                    <li>Saturday, November 2, 2024 9pm->Midnight on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                    <li>Saturday, November 16, 2024 9pm->Midnight on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                    <li>Saturday, December 7, 2024 9pm->Midnight on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                    <li>Saturday, December 21, 2024 9pm->Midnight on <a href="https://womr.org/" target="_blank" rel="noreferrer">WOMR</a></li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default App;
